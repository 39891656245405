import React from 'react'
import intl from 'react-intl-universal';
import { Container, Row, Col } from 'reactstrap';
import styles from './press-kit.module.css';

class PressKit extends React.Component {
  render() {
    return (
      <div className="page page-regular page-press-kit">
        <Container>
          <h2>{intl.get('pagePressKit')}</h2>
          <div className={styles.pictureContainer}>
            <div>
              <a href="/images/press/high-res/beaton-plasse-1.jpg">
                <img className="img-thumbnail" src="/images/press/low-res/beaton-plasse-1.jpg" alt={intl.get('pressKitCoverText')} /><br />
                {intl.get('pressKitCoverText')} <span className={styles.details}>(1648 x 1477 px / 300 dpi)</span>
              </a>
            </div>
            <div>
              <a href="/images/press/high-res/beaton-plasse-2.jpg">
                <img className="img-thumbnail" src="/images/press/low-res/beaton-plasse-2.jpg" alt={intl.get('pressKitOfficialPhotoText')} /><br />
                {intl.get('pressKitOfficialPhotoText')} <span className={styles.details}>(3300 x 3205 px / 300 dpi)</span>
              </a>
            </div>
          </div>
          <Row>
            <Col>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="beaton-plasse-brise-glace"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/wskuipMzxDw"
                  allow="autoplay; encrypted-media"
                  allowfullscreen>
                </iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default PressKit;
